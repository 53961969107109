<script setup lang="ts">
import Currency from "./Currency.vue";

const props = defineProps({
  value: {
    type: Number,
    default: 0,
  },
})

const isPositive = computed(() => {
  return props.value > 0;
})

</script>

<template>
  <span
    :class="{
      positive: isPositive,
      negative: !isPositive
    }"
  >
    <Currency :value="value" />
  </span>
</template>

<style lang="scss" scoped>
.positive {
  color: #17A61D;
}
.negative {
  color: #F0494F;
}
</style>
